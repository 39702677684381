import React, { Component } from "react";

class Radios extends Component {
  constructor() {
    super();
    this.state = {
    };
    this.onChangeValue = this.onChangeValue.bind(this);
    this.replhandle = this.replhandle.bind(this);
  }

  onChangeValue(a) {
    this.props.tmline(a.target.value);
  }
  replhandle(z) {
    this.props.replied(z.target.checked);
  }

  render() {
    return (
      <React.Fragment>
        <div className="radio-form" onChange={this.onChangeValue}>
          <span className="check-item">
            <input type="radio" value="tweets" id="recent" name="tmline" defaultChecked={this.props.curTimeline === "tweets"} />
            <label className="check-label" id="recent-label" htmlFor="recent">
              Recent
            </label>
          </span>
          <span className="check-item">
            <input type="radio" value="populars" id="popular" name="tmline" defaultChecked={this.props.curTimeline === "populars"} />
            <label className="check-label" htmlFor="popular">
              Popular
            </label>
          </span>
        </div>
        <span className="repl-check">
          <input
            type="checkbox"
            id = "repl"
            defaultChecked={this.props.curReplInc === "increpl"}
            onChange={this.replhandle}
          />
          <label className="check-label" htmlFor="repl">
            Include replies
          </label>
        </span>
      </React.Fragment>
    );
  }
}

export default Radios;
