import React from "react"
import Select from 'react-select'

const optionsel = [
  { value: "today", label: 'Last 24 hours' },
  { value: "yesterday", label: 'Yesterday' },
  { value: 'pereke', label: '2 Days ago' },
  { value: 'week', label: 'Last 7 days' }
]
function Datez() {
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+' | ' + today.getHours()+':'+today.getMinutes();
    return date;
}
class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-full ban">
        <header className="container">
            <nav className="navbar container navban navbar-light row">
              <a className="navbar-brand col-md-4" href="http://localhost:3001/">
                <span id="disanz" className="logotxt col-12">
                  <i className="imglogo ilogo col-1 fas fa-poll"></i>
                  <h1>Trends.krd.onl <span className="beta">Beta</span></h1>
                  <h2>A common timeline of Kurdish written tweets</h2>
                </span>
              </a>
              <div className="selright flright col-md-8">
                <span className="selcont">
                  <span className="seletxt">
                    View statistics for:
                  </span>
                  <Select
                    options={optionsel}
                    defaultValue={optionsel[0]}
                    classNamePrefix='selectList'
                    onChange={(e) => {
                      this.props.gdate(e);
                    }}
                  />
                </span>
                <span className="today">
                  now: {Datez()}
                  <span className="today-utc">
                    <i class="fas fa-info-circle"></i> dates in Beta are in UTC
                  </span>
                </span>
                <span className="socialz">
                  <span className="seletxt">
                    We are in:
                  </span>
                  <a href="https://twitter.com/trends_krd" target="blank" title="See us on Twitter">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="https://t.me/KrdOnl" target="blank" title="See Telegram Channel">
                    <i className="fab fa-telegram-plane"></i>
                  </a>
                </span>
              </div>
            </nav>
        </header>
      </div>
      </React.Fragment>
  );
  }
}

export default Header;
