import React from "react"
import ReactWordcloud from 'react-wordcloud';
import TodayChartTop from './todaycharttop'
import Summinfo from './summinfo'
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import axios from 'axios'
function Datez() {
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()//+' | ' + today.getHours()+':'+today.getMinutes();
    return date;
}
const cloudOptions = {
  rotations: 2,
  padding: 7,
  fontSizes: [24, 80],
  rotationAngles: [0, 90],
  colors: ["#fff"],
};

class Sum extends React.Component {
  state = {
    isFetching: false,
    wordz: [],
    top: [],
    pop: [],
    tweets: 0,
    users: 0,
  }

  getWordz() {
    this.setState({isFetching: true});
    const strnew = [];
    axios.get(`/api/wcloud/${this.props.gdate}`)
    .then(response => {
      this.setState({ wordz: response.data });
      this.setState({ isFetching: false });
    })

    axios.get(`/api/topusers/top/${this.props.gdate}`)
    .then(response => {
        this.setState({top: response.data});
      })
    .catch(error => console.log(error))
    axios.get(`/api/topusers/pop/${this.props.gdate}`)
    .then(response => {
        this.setState({pop: response.data});
      })
    .catch(error => console.log(error))
  }

    callbacks() {
      return (
        {
          getWordTooltip: word => `${word.text} (${word.value})`,
          onWordClick: word => {this.props.wordcloud(word.text.replace('#',''));
          },

        }
      )
    }

  componentDidMount(){
    this.getWordz()
  }
  componentDidUpdate(prevProps){
    if (prevProps.gdate !== this.props.gdate) {
      this.getWordz()
    };
  }
  render(props) {
    return (
    <div className="container-full topcont">
      <div className="summz topcontainer">
        <Summinfo source={this.props.gdate} />
          <p className="todaysum">Today: {Datez()}</p>

        <TodayChartTop source={this.props.gdate} />

        <div className="container-full wordcloud">
          {this.state.isFetching ? <h2>Fetching data...</h2> : <h2>Trends Word Cloud</h2> }
          <ReactWordcloud
            callbacks={this.callbacks()}
            options={cloudOptions}
            words={this.state.wordz}
          />
        </div>

        <div className="topz">
          <h2>Most active users: </h2>
          {this.state.top.map((usr) => {
            return(
              <span className="scrname">
                <img className="proimg" alt={usr.username} src= {usr.imgurl}/>
                <br></br>
                @{usr.userscrname}
                <br></br>
                <div className="device">
                  {usr.source}
                </div>
              </span>
            )
          })}
        </div>
        <div className="topz">
          <h2 className="popzh">Most popular users: </h2>
          {this.state.pop.map((usr) => {
            return(
              <span className="scrname">
                <img className="proimg" alt={usr.username} src= {usr.imgurl}/>
                <br></br>
                @{usr.userscrname}
                <br></br>
                <div className="device">
                  {usr.source}
                </div>
              </span>
            )
          })}
        </div>

      </div>
    </div>
  );
  }

}

export default Sum;
