import React, {Component} from 'react';
import axios from 'axios'
import { Scrollbars } from 'react-custom-scrollbars';

class List extends Component {
  constructor(){
    super();
    this.getWords.bind(this)
  };
  state = {
    hashtags: [],
    words: [],
    disan: "",
    loaded: true
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.gdate !== this.props.gdate) {
      this.getWords();
      this.getHashtags()
    };
  };

  getHashtags() {
    const newArr = [];
    axios.get(`/api/hashtags/${this.props.gdate}`)
    .then(response => {
      const strnew = response.data[0].tags.match(/\[".+?\]/g);
      strnew.forEach(item => {
        newArr.push({name: item.match(/\\".+?\"/g).toString().replace(/"/gi,'').replace(/\\/gi,''), value: parseInt(item.match(/\, .+?\]/g).toString().replace(/"/gi,"").replace(", ","").replace("]", ""))})
      });
    })
    .then(
      this.setState({hashtags: newArr})
    )
    .catch(error => console.log(error))
  };

  getWords() {
    const newArrz = [];
    axios.get(`/api/wordalones/${this.props.gdate}`)
    .then(response => {
      const strnew = response.data[0].words.match(/\[".+?\]/g);
      strnew.forEach(item => {
        newArrz.push({name: item.match(/\".+?\"/g).toString().replace(/"/gi,''), value: parseInt(item.match(/\, .+?\]/g).toString().replace(/"/gi,"").replace(", ","").replace("]", ""))})
      });
    })
    .then(
      this.setState({words: newArrz})
    )
    .catch(error => console.log(error))
  };

  componentDidMount() {
    this.getHashtags();
    this.getWords();
  };

  render() {
    return this.state.loaded ? (
      <div className="listWrapper">

        <div className="trendsbox margfif">
          <span className="trendstitle">
            <span className="trtitname">Keywords</span>
            <span className="trvalue">value</span>
          </span>
          <Scrollbars style={{ width: '100%', height: 230 }} >
             <div className="list-group list-group-flush">
               {this.state.words.map((todo) => {
                 return(
                   <li onClick={() => this.props.search(todo.name) } className="list-group-item" key={todo.name}>
                     <div className="liname">{todo.name}</div>
                     <span className="badge bg-light text-dark rounded-pill">{todo.value}</span>
                   </li>
                 )
               })}
             </div>
           </Scrollbars>
         </div>
         <div className="trendsbox">
           <span className="trendstitle">
             <span className="trtitname">Hashtags</span>
             <span className="trvalue">value</span>
           </span>
          <Scrollbars style={{ width: '100%', height: 230 }}>
             <ul className="list-group list-group-flush">
             {this.state.hashtags.map((todo) => {
               return(
                 <li onClick={() => this.props.search(todo.name) } className="list-group-item" key={todo.name}>
                   <div className="liname">#{todo.name}</div>
                   <span className="badge bg-light text-dark rounded-pill">{todo.value}</span>
                 </li>
               )
             })}
             </ul>
           </Scrollbars>
         </div>

    </div>
  ) : ( <div>...</div> )
  }
}

export default List;
