import React from 'react';
import axios from 'axios'
import TodayChart from './todaychart'
import { Scrollbars } from 'react-custom-scrollbars';

class Populars extends React.Component {

  state = {
    top: [],
    pop: [],
    tweets: 0,
    users: 0,
    isLoaded: false
  };

  getHashtags() {
    axios.get(`/api/topusers/top/${this.props.gdate}`)
    .then(response => {
        this.setState({top: response.data});
      })
    .catch(error => console.log(error))
    axios.get(`/api/topusers/pop/${this.props.gdate}`)
    .then(response => {
        this.setState({pop: response.data});
      })
    .catch(error => console.log(error))
  };

  componentDidMount(){
    this.getHashtags();
  };
  componentDidUpdate(prevProps){
    if (prevProps.gdate !== this.props.gdate) {
      this.getHashtags()
    }
  };
  render() {
    return (
      <div className="tops row">
        <div className="col-lg-6 devices topusers">
          <h2 className="tph2">Most used devices</h2>
          <TodayChart gdate = {this.props.gdate}/>
        </div>
        <div className="col-lg-3 topusers">
          <h2 className="tph2">Most active users</h2>
            <Scrollbars style={{ width: '100%', height: 500 }} >
            {this.state.top.map((usr) => {
              return(
                <li className="row usrow" key={usr.userscrname}>
                  <div className="usrimg col-2">

                    <a href={"https:/twitter.com/" + usr.userscrname} target="blank" title={"See" + usr.userscrname + "in Twitter"}>
                      <img className="proimg" alt={usr.username} src= {usr.imgurl}/>
                    </a>
                  </div>
                  <div className="usrcont col-10">
                    <a href={"https:/twitter.com/" + usr.userscrname} target="blank" title={"See " + usr.userscrname + " in Twitter"}>
                    <h2 className="fullname">{usr.username}</h2>
                    <span className="scrname">
                      @{usr.userscrname}
                    </span>
                    </a>
                    <div className="device">
                      <i className="fas fa-user-friends"></i> {usr.followers} followers<br/>
                      <i className="fas fa-tablet-alt"></i> {usr.source}
                    </div>
                  </div>
                </li>
              )
            })}
            </Scrollbars>
        </div>
        <div className="col-lg-3 topusers pop">
          <h2 className="tph2">Most popular users</h2>
          <Scrollbars style={{ width: '100%', height: 500 }} >

            {this.state.pop.map((usr) => {
              return(
                <li className="row usrow" key={usr.userscrname}>
                  <div className="usrimg col-2">
                    <a href={"https:/twitter.com/" + usr.userscrname} title={"See" + usr.userscrname + "in Twitter"}>
                      <img className="proimg" alt={usr.username} src= {usr.imgurl}/>
                    </a>
                  </div>
                  <div className="usrcont col-10">
                    <a href={"https:/twitter.com/" + usr.userscrname} target="blank" title={"See " + usr.userscrname + " in Twitter"}>
                    <h2 className="fullname">{usr.username}</h2>
                    <span className="scrname">
                      @{usr.userscrname}
                    </span>
                    </a>
                    <div className="device">
                      <i className="fas fa-user-friends"></i> {usr.followers} followers<br/>
                      <i className="fas fa-tablet-alt"></i> {usr.source}
                    </div>
                  </div>
                </li>
              )
            })}
            </Scrollbars>
        </div>
      </div>

      )
    }

}
export default Populars;
