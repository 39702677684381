import React from 'react';
import axios from 'axios'
import ReactECharts from 'echarts-for-react';

class TodayChart extends React.Component {

  state = {
    dalue: [],
    options: {},
    isLoaded: false
  };

  getHashtags() {
    const strnew = [];
    axios.get(`/api/sources/${this.props.gdate}`)
    .then(response => {
      for (const [key, value] of Object.entries(response.data)) {
        strnew.push({value: value, name: key});
      };
    })
    .then(aa=>{
      this.setState({ dalue: strnew });
    })
    .then(aaz=>{
      const options = {
        title: {
            text: 'Sources',
            subtext: 'krd.onl',
            show: false,
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            show: false
        },toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            feature: {
                mark: {show: true},
                dataView: {show: true, readOnly: false},
                magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
                restore: {show: true},
                saveAsImage: {show: true}
            }
        },
        series: [
            {
                name: 'Sources',
                type: 'pie',
                radius: '50%',
                data: this.state.dalue,
                label: {
                    formatter: '{name|{b}}\n{time|{c}}',
                    lineHeight: 15,
                    textStyle: {
                        color: '#999',
                        fontWeight: 'bold',
                        fontSize: 16
                    },
                    rich: {
                        time: {
                            fontSize: 16,
                            color: '#999'
                        }
                    }
                },
                labelLine: {
                    length: 35,
                    length2: 10,
                    maxSurfaceAngle: 80
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    },
                }
            }
        ]
    };
      this.setState({ isLoaded: true });
      this.setState({ options: options });
    })
    .catch(error => console.log(error));
  };

  componentDidMount() {
    this.getHashtags();
  };
  componentDidUpdate(prevProps){
    if (prevProps.gdate !== this.props.gdate) {
      this.getHashtags();
    }
  }
  render() {
    return this.state.isLoaded ? (
      <ReactECharts option={this.state.options} style={{height: 400}} />
  ) : (
            <div>Loading...</div>
        );
  }

}
export default TodayChart;
