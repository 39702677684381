import './App.css';
import MyCloud from './components/MyCloud'
import Header from './components/header'
import Chxwar from './components/chxwar'
import Timeline from './components/timeline'
import Populars from './components/populars'
import Footer from './components/footer'
import 'bootstrap/dist/css/bootstrap.css';
import React, { useState } from 'react';

function Home() {
  const [date, gdate] = useState("today");
  const [wordcloud, swordcloud] = useState("");

  return (
    <React.Fragment>
      <Header gdate = {(a) => {
        gdate(a.value)
      }}/>
      <MyCloud
        gdate = {date}
        wordcloud = {(word) => {
            swordcloud(word)
          }
        }
      />
      <div className="container timeline">
        <Timeline gdate = {date}
          swordcloud = {wordcloud}
        />
      </div>
      <div className="container populars">
        <Populars gdate = {date} />
      </div>
      <div className="container">
        <Chxwar/>
      </div>
      <Footer/>

    </React.Fragment>
  );
}

export default Home;
