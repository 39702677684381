import React from 'react';
import axios from 'axios'
import ReactECharts from 'echarts-for-react';

class Chxwar extends React.Component {
  state = {
    data: [],
    options: {},
    isLoaded: false
  };

  getHashtags() {
    axios.get('/api/twperday')
    .then(aa=>{
      this.setState({ data: aa.data });
    })
    .then(aaz=>{
      var app = {};

        var posList = [
          'left', 'right', 'top', 'bottom',
          'inside',
          'insideTop', 'insideLeft', 'insideRight', 'insideBottom',
          'insideTopLeft', 'insideTopRight', 'insideBottomLeft', 'insideBottomRight'
      ];

      app.configParameters = {
          rotate: {
              min: -90,
              max: 90
          },
          align: {
              options: {
                  left: 'left',
                  center: 'center',
                  right: 'right'
              }
          },
          verticalAlign: {
              options: {
                  top: 'top',
                  middle: 'middle',
                  bottom: 'bottom'
              }
          },
          position: {
              options: posList.reduce(function (map, pos) {
                  map[pos] = pos;
                  return map;
              }, {})
          },
          distance: {
              min: 0,
              max: 100
          }
      };

      app.config = {
          rotate: 90,
          align: 'left',
          verticalAlign: 'middle',
          position: 'insideBottom',
          distance: 15,
      };


      var labelOption = {
          show: true,
          position: app.config.position,
          distance: app.config.distance,
          align: app.config.align,
          verticalAlign: app.config.verticalAlign,
          rotate: app.config.rotate,
          formatter: '{c}  {name|{a}}',
          fontSize: 16,
          rich: {
              name: {
              }
          }
      };
      const options = {
          title: {
            text: 'This Weeks Overview',
            left: "center",
            top: 0,
            textStyle: {
              color: '#000',
              fontSize: 16,
              fontFamily: "Ubuntu",
            }
          },
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'shadow'
              }
          },
          legend: {
              top: 30,
              data: ['Tweet', 'Retweet', 'with Hashtag', 'with Media']
          },
          toolbox: {
              show: true,
              orient: 'vertical',
              left: 'right',
              top: 'center',
              feature: {
                  mark: {show: true},
                  dataView: {show: true, readOnly: false},
                  magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
                  restore: {show: false},
                  saveAsImage: {show: true}
              }
          },
          xAxis: [
              {
                  type: 'category',
                  axisTick: {show: false},
                  data: [
                    this.state.data.lstdate,
                    this.state.data.pnjdate,
                    this.state.data.chdate,
                    this.state.data.pesdate,
                    this.state.data.perdate,
                    this.state.data.yestdate ,
                    `Today - ${this.state.data.todaydate}`
                  ]
              }
          ],
          yAxis: [
              {
                  type: 'value'
              }
          ],
          series: [
              {
                  name: 'Tweet',
                  type: 'bar',
                  barGap: 0,
                  label: labelOption,
                  emphasis: {
                      focus: 'series'
                  },
                  data: [
                    this.state.data.lstt,
                    this.state.data.pnjt,
                    this.state.data.cht,
                    this.state.data.pest,
                    this.state.data.pert,
                    this.state.data.yestt ,
                    this.state.data.todayt
                  ]
              },
              {
                  name: 'Retweet',
                  type: 'bar',
                  label: labelOption,
                  emphasis: {
                      focus: 'series'
                  },
                  data: [
                    this.state.data.lstr,
                    this.state.data.pnjr,
                    this.state.data.chr,
                    this.state.data.pesr,
                    this.state.data.perr,
                    this.state.data.yestr ,
                    this.state.data.todayr
                  ]
              },
              {
                  name: 'with Hashtag',
                  type: 'bar',
                  label: labelOption,
                  emphasis: {
                      focus: 'series'
                  },
                  data: [
                    this.state.data.lsth,
                    this.state.data.pnjh,
                    this.state.data.chh,
                    this.state.data.pesh,
                    this.state.data.perh,
                    this.state.data.yesth ,
                    this.state.data.todayh
                  ]
              },
              {
                  name: 'with Media',
                  type: 'bar',
                  label: labelOption,
                  emphasis: {
                      focus: 'series'
                  },
                  data: [
                    this.state.data.lstm,
                    this.state.data.pnjm,
                    this.state.data.chm,
                    this.state.data.pesm,
                    this.state.data.perm,
                    this.state.data.yestm ,
                    this.state.data.todaym
                  ]
              }
          ]
      };
      this.setState({ isLoaded: true });
      this.setState({ options });
    })
    .catch(error => console.log(error));

  };

  componentDidMount() {
    this.getHashtags();
  };
  render() {
    return this.state.isLoaded ? (
      <div className="row">
        <div className="col-md-12 overview">
          <ReactECharts option={this.state.options} style={{ height: 520 }} />
        </div>
      </div>
  ) : (
            <div>Loading...</div>
        );
  }

}
export default Chxwar;
