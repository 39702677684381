import React from 'react';

class Footer extends React.Component {

  render() {
    return (
      <React.Fragment>
        <footer className="container-full">
          <div className="container footer-container">
            <div className="fleft">
              <div className="links">
                To contact, leave us a message on
                <a href="https://twitter.com/trends_krd" target="blank" title="See us on Twitter"> Twitter
                </a>
                 .
              </div>
              <div className="cr">This software is provided to create a common timeline of tweets written in Kurdish language in all writing systems.
              It also hopes to be a help to get easy on Kurdish Twitter and make it easier to connect with others on the same topics.</div>
              <div className="cr">Copyright © 2021 Krd.onl. All rights reserved.</div>
            </div>
            <div className="fright">
              <h2>KRD.ONL</h2>
            </div>
          </div>
        </footer>
      </React.Fragment>
  );
  }
}

export default Footer;
