import './App.css';
import Sum from './components/sum'
import Populars from './components/populars'
import 'bootstrap/dist/css/bootstrap.css';
import React, { useState } from 'react';
import { useRef } from 'react';
const safeDocument = typeof document !== 'undefined' ? document : {};


function Summary() {
  const [date, gdate] = useState("today");
  const [wordcloud, swordcloud] = useState("");
  const html = safeDocument.documentElement;
  html.style.overflow = 'hidden';
  return (
    <React.Fragment>
      <Sum
        gdate = {date}
        wordcloud = {(word) => {
            swordcloud(word)
          }
        }
      />
    </React.Fragment>
  );
}

export default Summary;
