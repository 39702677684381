import React from 'react';
import axios from 'axios'

class Summinfo extends React.Component {
  state = {
    dalue: [],
    tweets: 0,
    users: 0,
    datetxt: "Last 24 hours",
    isLoaded: false
  };

  getHashtags() {
    let strnew = [];
    axios.get('/api/usersperday')
    .then(response => {
      for (const [key, value] of Object.entries(response.data)) {
        strnew.push({name: key, value: [value[0], value[1]]}) ;
      }
    })
    .then(aa=>{
      this.setState({ dalue: strnew });
    })
    .then(b=>{
      this.getChange();
    })
    .catch(error => console.log(error));
  };
  getChange(){
    this.state.dalue.map((itm) =>
      itm.name === this.props.source ?  this.setState({tweets: itm.value[0], users: itm.value[1]}) : null
    );

  };
  componentDidMount() {
    this.getHashtags();
  };
  componentDidUpdate(prevProps) {
    if (prevProps.source !== this.props.source) {
      this.getChange();
      if (this.props.source === "today") {
        this.setState({datetxt: "Last 24 hours"})
      } else if (this.props.source === "yesterday") {
        this.setState({datetxt: "Yesterday"})
      } else if (this.props.source === "pereke") {
        this.setState({datetxt: "2 Days ago"})
      } else if (this.props.source === "week") {
        this.setState({datetxt: "Last 7 days"})
      }
    };

  }
  render() {
    return (
      <div className="container summary">
        <h2>Summary of {this.state.datetxt}</h2>
        <span className="tweetscount twcountbord">
          <span className="countname"><i className="fas fa-comments"></i> Tweets:</span> {this.state.tweets.toLocaleString()}
        </span>
        <span className="tweetscount">
          <span className="countname"><i className="fas fa-users"></i> Users:</span> {this.state.users.toLocaleString()}
        </span>
      </div>
      )
    }

}
export default Summinfo;
