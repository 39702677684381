import React from "react"
import ReactWordcloud from 'react-wordcloud';
import TodayChartTop from './todaycharttop'
import Summinfo from './summinfo'
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import axios from 'axios'

const cloudOptions = {
  rotations: 2,
  padding: 7,
  fontSizes: [28, 84],
  rotationAngles: [0, 90],
  colors: ["#2a265f"],
};

class MyCloud extends React.Component {
  state = {
    isFetching: false,
    wordz: [],
  }

  getWordz() {

    this.setState({isFetching: true});
    const strnew = [];
    axios.get(`/api/wcloud/${this.props.gdate}`)
    .then(response => {
      this.setState({ wordz: response.data });
      this.setState({ isFetching: false });
    })
  }

    callbacks() {
      return (
        {
          getWordTooltip: word => `${word.text} (${word.value})`,
          onWordClick: word => {this.props.wordcloud(word.text.replace('#',''));
          },

        }
      )
    }

  componentDidMount(){
    this.getWordz()
  }
  componentDidUpdate(prevProps){
    if (prevProps.gdate !== this.props.gdate) {
      this.getWordz()
    };
  }
  render(props) {
    return (
    <div className="container-full topcont">
      <div className="container topcontainer">
        <Summinfo source={this.props.gdate} />
        <TodayChartTop source={this.props.gdate} />
        <div className="wordcloud">
          {this.state.isFetching ? <h2>Fetching data...</h2> : <h2>Trends Word Cloud</h2> }
          <ReactWordcloud
            callbacks={this.callbacks()}
            options={cloudOptions}
            words={this.state.wordz}
          />
        </div>
      </div>
    </div>
  );
  }

}

export default MyCloud;
