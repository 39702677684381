import React from 'react';
import axios from 'axios'
import ReactECharts from 'echarts-for-react';

class TodayChartTop extends React.Component {
  state = {
    dalue: [],
    options: {},
    isLoaded: false
  };

  getHashtags() {
    const strnew = [];
    axios.get('/api/twperhr')
    .then(response => {
      for (const [key, value] of Object.entries(response.data[this.props.source])) {
        strnew.push([ 0, parseInt(key), value]);
      };
    })
    .then(aa=>{
      this.setState({ dalue: strnew });
    })
    .then(aaz=>{

var hours = ['0', '1', '2', '3', '4', '5', '6',
        '7', '8', '9','10','11',
        '12', '13', '14', '15', '16', '17',
        '18', '19', '20', '21', '22', '23'];
var days = ['ئەمڕۆ'];

var data = this.state.dalue;
data = data.map(function (item) {
    return [item[1], item[0], item[2]];
});
var sourcedate = this.props.source;
const options = {
    title: {
        text: 'Tweets Over Hour',
        left: "center",
        top: 20,
        textStyle: {
          color: '#80bdbd',
          fontSize: 14,
          fontFamily: "Ubuntu"
        }
    },
    tooltip: {
        position: 'top',
        textStyle: {
          color: '#80bdbd',
          fontSize: 16,
          fontFamily: "Ubuntu"
        },
        backgroundColor: '#2b2b2b',
        formatter: function (params) {
          return params.value[2] + ' Tweets at: ' + hours[params.value[0]] + ':00';
        }
    },
    grid: {
        left: 2,
        bottom: 10,
        right: 10,
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: hours,
        axisLabel: {
            color: '#52ccbd',
        },
        boundaryGap: false,
        splitLine: {
            show: true,
            lineStyle: {
                color: '#3a9696',
                type: 'dashed'
            }
        },
        axisLine: {
            show: false
        }
    },
    yAxis: {
        type: 'category',
        data: days,
        show: false,
        axisLine: {
            show: false
        }
    },
    series: [{
        name: 'Punch Card',
        type: 'scatter',
        symbolSize: function (val, props) {
            if (sourcedate === "week") {
              return (val[2] / 90)
            } else {
              return (val[2] / 30)
            }
        },
        color: '#2a265f',
        data: data,
        animationDelay: function (idx) {
            return idx * 5;
        }
    }]
};
      this.setState({ isLoaded: true });
      this.setState({ options });
    })
    .catch(error => console.log(error));

  };

  componentDidMount() {
    this.getHashtags();
  };
  componentDidUpdate(prevProps) {
    if (prevProps.source !== this.props.source) {
      this.getHashtags();
    }
  }
  render() {
    return this.state.isLoaded ? (
      <ReactECharts option={this.state.options} style={{ height: 120 }} />
  ) : (
            <span className="loadi"><div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading charts</span></div></span>
        );
  }

}
export default TodayChartTop;
