import React, { useEffect, useState} from 'react';
import axios from 'axios'
import List from './list'
import Radios from './radios'
import { Scrollbars } from 'react-custom-scrollbars';

function chdate(date){
  var today = new Date().toLocaleDateString("en-US");
  var localDate = new Date(date).toLocaleDateString("en-US");
  date = new Date(date);

  if (today === localDate) {
    return date.getHours() + ":" + date.getMinutes() + " - Today";
  } else {
    return  date.getHours() + ":" + date.getMinutes() + " - " + date.getDate() + "." + date.getMonth() + "." + date.getFullYear();
  }
};
function Timeline(props) {

const [tweets, setTweets] = useState([]);
const [page, setPage] = useState(1);
const [date, setDate] = useState(props.gdate);
const [word, search] = useState("");
const [timeline, setTimeline] = useState("tweets");
const [replied, setReplied] = useState("exrepl");
const [isFetching, setIsFetching] = useState(false);


useEffect(() => {
  setTweets([]);
  setPage(1);
  setDate(props.gdate)
}, [props.gdate]);

useEffect(() => {
  setTweets([]);
  setPage(1);
  search(props.swordcloud);
}, [props.swordcloud]);


useEffect(() => {
  const getTweets = () => {
    setIsFetching(true);
    axios.get(`/api/${timeline}/d/${date}/${replied}?t_full_text=${word}&page=${page}`)
    .then(res => {
      setTweets([...tweets, ...res.data]);
      setIsFetching(false);
    });

  };
  getTweets();
}, [timeline, replied, word, page, date]);


    return (
      <div className="row">
        <div className="col-md-4 flright">
          <List
            search = {(a) => {
              setTweets([]);
              setPage(1);
              search(a)
            }}
            gdate = {props.gdate}
          />
        </div>
        <div className="col-md-8 timeline-block">
          <span className="tmtitle">
            Timeline
            <span className="radioz">
              <Radios
              tmline={(b)=> {
                  setTimeline(b);
                  setTweets([]);
                  setPage(1);
              }}
              replied={(z)=> {
                  setPage(1);
                  setTweets([]);
                  z === true ? setReplied("increpl") : setReplied("exrepl")
              }}
              curTimeline = {timeline}
              curReplInc = {replied}
              />
            </span>
          </span>
          <span className="filter">
            <span className="filinfo">
            {
              word !== "" ? <div className="wordz">Filtered by: <span onClick={()=> {search(""); setPage(1); setTweets([]); }}>{word} | <i className="fas fa-times"></i></span></div> : <span><i className="fas fa-info"></i> Filter the timeline using Trend List - *retweets are not included</span>
            }
            </span>
          </span>
          <Scrollbars
            style={{ width: '100%', height: 490 }}
          >
             {tweets.map((tweet) => {
               return(
                 <div className="row tweet" key={tweet.id}>

                  <div className="col-2 col-md-1">
                    <a href={"https:/twitter.com/" + tweet.user_screen_name} target="blank" title={"See" + tweet.user_screen_name + "in Twitter"}>
                      <img className="proimg" alt={tweet.user_name} src= {tweet.user_profile_image_url}/>
                    </a>
                  </div>
                  <div className="col-10 col-md-11">
                    <span className="username">
                      <a href={"https:/twitter.com/" + tweet.user_screen_name} target="blank" title={"See " + tweet.user_screen_name + " in Twitter"}>
                      <h2 className="fullname">
                        {tweet.user_name}
                        {tweet.user_verified === "t" ? <i className="fas fa-check-circle"></i> : null}
                      </h2>
                      <span className="scrname">
                        @{tweet.user_screen_name}
                      </span>
                      </a>
                    </span>
                    <div className="row twcont">
                      <p className={tweet.t_lang !== "tr" ? "rtl" : null}>{tweet.t_full_text}</p>
                      <span className="media row">
                        {tweet.t_media !== "" ? <img alt={tweet.user_name} src={tweet.t_media} /> : null}
                      </span>
                      <span className="media row">
                        {tweet.t_media_type !== null ? <video src={tweet.t_media_type} controls /> : null}
                      </span>
                      <span className="infos">
                        <span className="like">
                          <i className="far fa-heart"></i> {tweet.t_favorite_count}
                        </span>
                        <span className="retw">
                          <i className="fas fa-retweet"></i> {tweet.t_retweet_count}
                        </span>
                        <span className="time">{chdate(tweet.t_created_at)} | {tweet.source}</span>
                        <span className="see">
                          <a href={tweet.t_url} target="blank" title={"See the tweet in Twitter"}>
                            <i className="fas fa-external-link-alt"></i> open in Twitter
                          </a>
                        </span>
                      </span>
                    </div>
                  </div>
                 </div>
               )
             })}
             <p className="btn-load-more" onClick={() => setPage(page + 1)}>

            {!isFetching ? <span className="load-more"><i className="fas fa-ellipsis-v"></i>Load more</span> : <div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div>}</p>
          </Scrollbars>
        </div>
      </div>
  );
}

export default Timeline;
